<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'疫情隔离'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入隔离人员名字搜索" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="symptomShow = !symptomShow" :style="{color:symptomShow?'#387FF5':'#666666'}">
            {{symptomName}}
            <img :src="require(`@/assets/img/${symptomShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="stateShow = !stateShow" :style="{color:stateShow?'#387FF5':'#666666'}">
            {{stateName}}
            <img :src="require(`@/assets/img/${stateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="reportShow = !reportShow" :style="{color:reportShow?'#387FF5':'#666666'}">
            {{nucleicAcidReport}}
            <img :src="require(`@/assets/img/${reportShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="sourceShow = !sourceShow" :style="{color:sourceShow?'#387FF5':'#666666'}">
            {{sourceName}}
            <img :src="require(`@/assets/img/${sourceShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="symptomShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="symptomList" value-key="label" @confirm="symptomConfirm" @cancel="symptomCancel"  />
    </van-popup>
    <van-popup v-model="stateShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="stateList" value-key="label" @confirm="stateConfirm" @cancel="stateCancel"  />
    </van-popup>
    <van-popup v-model="reportShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="reportList" value-key="label" @confirm="reportConfirm" @cancel="reportCancel"/>
    </van-popup>
    <van-popup v-model="sourceShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" show-toolbar :columns="sourceList" value-key="label" @confirm="sourceConfirm" @cancel="sourceCancel"  />
    </van-popup>
    <van-dialog v-model="phoneShow" title="" :message="content" @confirm="contentConfirm" show-cancel-button>
    </van-dialog>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="(item, index) in dataList" :key="index" @click="getInfo(item.id, item.userId)">
          <div class="title">
            <div class="title-left">
              隔离时间{{' | ' + item.isolateTime}}
            </div>
            <div class="title-right">
              第{{item.isolateDay}}天
            </div>
          </div>
          <div class="content">
            <img v-if="item.url" :src="imgUrl(item.url)" class="content-img" @error="error(index, item.sex)"/>
            <img v-else-if="item.sex == '1'" :src="imgMan" class="content-img" />
            <img v-else-if="item.sex == '2'" :src="imgWoman" class="content-img" />
            <div class="content-text">
              <div class="top-text"><span>{{item.userName}}</span> {{item.age + '岁'}} {{item.sex == 1 ?'男':'女'}}</div>
              <div class="top-text">{{item.fullName}}</div>
              <div class="top-text">是否上报：{{item.reportTime}}</div>
              <div class="top-text"><span>主要症状：{{item.symptom}}</span></div>
            </div>
            <van-icon name="phone" color="#3D7EFE" class="content-icon" size="0.7rem" @click="callPhone(item.mobile)"/>
          </div>
        </div>
      </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {mobile, DateFormat} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations} from "vuex";
export default {
  components :{
    topBar
  },
  data() {
    return {
      symptomName: '症状',
      stateName: '状态',
      nucleicAcidReport: '核酸报告',
      sourceName: '来源',
      symptomShow: false,
      stateShow: false,
      reportShow: false,
      sourceShow: false,
      phoneShow: false,
      page: 0,
      limit: 10,
      searchValue: '',
      content: '',
      mobile: '',
      dataForm: {
        symptom: '', //症状
        isolationType: '', //来源
        reportNum: '',
        reportType: '',
        //搜索
        userName: '',
        orgId: '',
        orgDepth: 5,
        userType: ''
      },
      loading: false,
      finished: false,
      pullLoading: false,
      symptomList: [
        { id: 1, label: "发热", value: 'fever' },
        { id: 2, label: "咳嗽", value: 'cough' },
        { id: 3, label: "乏力", value: 'weak' }
      ],
      stateList: [
        { id: 0, label: '隔离中' }, { id: 1, label: '已解除' }, { id: 2, label: '转疑似病例' }, { id: 3, label: '转确诊病例' }
      ],
      reportList: [ {
        values: ['第一次', '第二次', '第三次', '第四次', '第五次', '第六次', '第七次'],
        defaultIndex: 0,
      },
      // 第二列
      {
        values: ['未上传', '已上传'],
        defaultIndex: 0,
      },],
      sourceList: [{label: '国内中高风险市低风险区进入', value: '1'}, {label: '境外进入', value: '2'}],
      totalCount: 0,
      dataList: [],
      imgMan: getImageStream('files/wx/images/content/headImgMan.png'),
      imgWoman: getImageStream('files/wx/images/content/headImgWoman.png')
    };
  },
  methods: {
    ...mapMutations,
    imgUrl (img) {
      if (img) {
        return getImageStream(img)
      }
    },
    error (index, sex) {
      if (sex == '1') {
        this.dataList[index].url = "files/wx/images/content/headImgMan.png"
      } else {
        this.dataList[index].url = "files/wx/images/content/headImgWoman.png"
      }
    },
    callPhone (mobile){
      if (!mobile) {
        this.$toast.fail('联系电话不存在')
      } else {
        this.phoneShow = true
        this.content = '是否拨打电话' + '' + '【' + mobile + '】' + '' + '?'
        this.mobile = mobile
      }
    },
    contentConfirm () {
      window.location.href = 'tel:' + this.mobile
    },
    getInfo (id, userId) {
      this.$router.push({path: '/isolate-info', query: {id: id, userId: userId}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/res/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          userName: this.searchValue,
          symptom: this.dataForm.symptom,
          isolationType: this.dataForm.isolationType,
          reportNum: this.dataForm.reportNum,
          reportType: this.dataForm.reportType,
          orgId: this.$orgId,
          orgDepth: this.dataForm.orgDepth,
          userType: this.dataForm.userType
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
            data.page.list.map(e => {
            var isolateDay = 0
            var reportTime = '今日未上报'
            var symptom = '无'
            let toDay = Date.parse(new Date())
            let isolationDate = Date.parse(e.isolationDate)
            let total = parseInt((toDay - isolationDate) / (1000 * 60 * 60 * 24))
            isolateDay = total
            if (e.isolationObservations.length > 0) {
              var list = e.isolationObservations[0]
              //判断日期是不是当天
              var nD = DateFormat(new Date())
              // var isolationD=util.formatDate(list.observationDate)
              if (list.observationDate.indexOf(nD) !== -1) {
                symptom = list.symptoms
                reportTime = '今日已上报'
              }
            }
            var index = e.isolationObservations.length - 1
            var mainSymptom = ''
            if (index >= 0) {
              if (e.isolationObservations[0].cough) {
                mainSymptom = mainSymptom + '咳嗽'
              }
              if (e.isolationObservations[0].fever) {
                mainSymptom = mainSymptom + '发烧'
              }
              if (e.isolationObservations[0].weak) {
                mainSymptom = mainSymptom + '乏力'
              }
            }
           this.dataList.push({
              id: e.id,
              userId: e.userId,
              userName: e.userName,
              age: e.age,
              sex: e.sex,
              fullName: e.subareaName + e.houseName,
              mobile: e.mobile,
              url: e.headImg,
              isolateTime: e.isolationDate,
              isolateDay: isolateDay,
              reportTime: reportTime,
              symptom: index >= 0 ? mainSymptom : '无'
            })
          })
          // this.dataList = this.dataList.concat(this.dataList)
          // console.log(this.dataList)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    onSearch () {
      this.page = 0
      this.dataList = []
      this.getDataList()
    },
    onCancel () {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.searchValue = ''
      this.dataForm.symptom = ''
      this.dataForm.isolationType = ''
      this.dataForm.reportType = ''
      this.dataForm.reportNum = ''
      this.dataForm.userType = ''
      this.symptomName = '症状'
      this.stateName = '状态'
      this.nucleicAcidReport = '核酸报告'
      this.sourceName = '来源'
      this.getDataList()
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    symptomConfirm (value) {
      this.page = 0
      this.dataList = []
      this.symptomName = value.label
      this.dataForm.symptom = value.id
      this.getDataList()
      this.symptomShow = false
    },
    symptomCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.symptom = ''
      this.symptomName = '症状'
      this.symptomShow = false
      this.getDataList()
    },
    stateConfirm (value) {
      this.page = 0
      this.dataList = []
      this.stateName = value.label
      this.dataForm.isolationType = value.id
      this.getDataList()
      this.stateShow = false
    },
    stateCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.isolationType = ''
      this.stateName = '状态'
      this.stateShow = false
      this.getDataList()
    },
    reportConfirm (value, index) {
      this.page = 0
      this.dataList = []
      this.nucleicAcidReport = value[0] + value[1]
      this.dataForm.reportType = index[1]
      this.dataForm.reportNum = index[0]
      this.getDataList()
      this.reportShow = false
    },
    reportCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.reportType = ''
      this.dataForm.reportNum = ''
      this.nucleicAcidReport = '核酸报告'
      this.reportShow = false
      this.getDataList()
    },
    sourceConfirm (value) {
      this.page = 0
      this.dataList = []
      this.sourceName = value.value === '2'? '国外' : '国内'
      this.dataForm.userType = value.value
      this.getDataList()
      this.sourceShow = false
    },
    sourceCancel () {
      this.page = 0
      this.dataList = []
      this.dataForm.userType = ''
      this.sourceName = '来源'
      this.sourceShow = false
      this.getDataList()
    },
    goAdd () {
      this.$router.push('/isolate-add')
    }
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.van-dialog {
  width: 550px;
}
.list-item {
  padding: 0 0.32rem 0.32rem!important;
}
.title-right {
  background-color: red;
  color: white !important;
  font-weight: bold;
  line-height: 35px !important;
  margin-top: 20px;
  border-radius: 10px;
}
.content-img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50%;
  border: 0.5px solid #999999;
}
.content-text {
  margin-left: 50px !important;

  .top-text {
    color: #999999 !important;
    font-size: 24px !important;
    width: 420px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span:nth-child(1) {
      font-size: 26px;
      color: #000;
    }
  }
  .top-text:nth-child(4) span {
    font-size: 24px;
    color: #3E7EFE;
  }
}
</style>
